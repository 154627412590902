export const searchTypeOptions = [
	{
		displayText: 'Texto libre',
		value: 'fulltext',
		selected: true,
	},
	{
		displayText: 'Título',
		value: 'titulo',
		selected: false,
	},
	{
		displayText: 'Autor',
		value: 'autores',
		selected: false,
	},
	{
		displayText: 'Palabra clave',
		value: 'material',
		selected: false,
	},
	{
		displayText: 'Editorial',
		value: 'editorial',
		selected: false,
	},
	{
		displayText: 'ISBN',
		value: 'ISBN',
		selected: false,
	},
	{
		displayText: 'Clasificación',
		value: 'clasificacion',
		selected: false,
	},
];

export const booleanOptions = [
	{
		displayText: 'and',
		value: 'AND',
		selected: true,
	},
	{
		displayText: 'or',
		value: 'OR',
		selected: false,
	},
	{
		displayText: 'and not',
		value: 'NAND',
		selected: false,
	},
];

export const sortOptions = [
	{
		displayText: 'Título',
		value: 'sorttitle',
		selected: true,
	},
	{
		displayText: 'Autor',
		value: 'sortauthor',
		selected: false,
	},
	{
		displayText: 'Clasificación',
		value: 'sortclasification',
		selected: false,
	},
];
